<template>
  <v-container>
    <v-row class="mb-10 justify-space-between">
    <v-col>
      <h1 class="text text-h4 titulo"><v-icon class="mr-2 align-items-center"></v-icon>Proveedores</h1>
    </v-col>
    <v-col class="text-end">
      <v-btn class="ml-4" color="success" @click="$router.push({name:'ImportProviders'})" text>
          <v-icon class="mr-2">fas fa-file-import</v-icon>Importar
      </v-btn>
      <v-btn class="ml-4" color="success" @click="openCreate()">
          <v-icon class="mr-2">fas fa-plus</v-icon>Nuevo
      </v-btn>
    </v-col>
  </v-row>
  <v-row justify="end">
    <v-col cols="12" md="6">
      <v-text-field 
        v-model="search"
        @keyup="searchTimeOut"
        label="Buscar"
        append-icon="fas fa-search"
        :disabled="loading"
      ></v-text-field>
    </v-col>
  </v-row>
  <v-row>
    <v-col>
      <v-data-table
          class=""
          :headers="headers"
          :items="providers"
          :items-per-page="10"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          :search="search"
          dense
          sort-by='name'
          item-class="table-body"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'fas fa-angle-double-left',
            lastIcon: 'fas fa-angle-double-right',
            prevIcon: 'fas fa-angle-left',
            nextIcon: 'fas fa-angle-right'
          }">
          <template v-slot:item.name="{ item }">
            {{item.name}}
          </template>
          <template v-slot:item.estatus="{ item }">
            <v-switch v-model="item.estatus" @change="updateStatus(item)"></v-switch>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon  class="mr-2" @click.stop="update(item.id)">fas fa-edit</v-icon>
            <!-- <v-icon class="error--text" @click.stop="deleteItem(item)">delete</v-icon> -->
          </template>
        </v-data-table>
    </v-col>
  </v-row>
  <v-dialog v-model="showDialog" max-width="500">
    <v-card class="px-10 py-4">
      <provider-create-update :id="provider" @updated="fetch" @close="showDialog=false"/>
    </v-card>
  </v-dialog>
  </v-container>
</template>

<script>
import {fetchProvider,updateProvider} from '@/api/stocks'
import notifications from '@/utils/notifications'
import ProviderCreateUpdate from '@/components/stocks/ProviderCreateUpdate'

export default {
  name:'Providers',
  components:{ProviderCreateUpdate},
  data() {
    return {
      loading: false,
      providers:[],
      provider:'',
      timer:'',
      options:'',
      search:'',
      total:'',
      showDialog:false,
      headers: [
      {
        text: 'RUT',
        align: 'start',
        value: 'rut',
        class: 'table-header',
        
      },
      {
        text: 'Nombre',
        align: 'start',
        value: 'name',
        class: 'table-header'
      },
      {
        text: 'Cond. Pago',
        align: 'end',
        value: 'payment',
        class: 'table-header',
        with:'50px'
      },
      {
        text: 'Estatus',
        align: 'start',
        value: 'estatus',
        class: 'table-header'
      },
      { text: 'Acciones',value:'actions',class: 'table-header',align:'center',sortable: false}
    ],
    }
  },
  methods:{
    async fetch(){
      this.loading=true
      const {sortBy, sortDesc, page, itemsPerPage }=this.options
      let sort=''
      sort = sortBy[0]
      const query=`ordering=${sortDesc[0]?sort:'-' + sort}&&page=${page}&&page_size=${itemsPerPage}&&search=${this.search}`
      await fetchProvider(query)
      .then(res=>{
        this.total=res.count
        this.providers=res.results
      })
      .catch((err)=>{
        notifications.showError('Error cargando proveedores '+err)
      })
      this.loading=false
    },
    searchTimeOut() {  
      //Función para hacer búsqueda lazy
      if (this.timer) {
          clearTimeout(this.timer);
          this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.options.page=1
          this.fetch()
      }, 800);
    },
    openCreate(){
      this.provider={
        id:-1,
        rut:null,
        name:null,
        payment:null
      }
      this.showDialog=true
    },
    update(item){
      this.provider=item
      this.showDialog=true
    },
    async updateStatus(item){
      console.log(item)
      await updateProvider(item.id,{estatus: item.estatus})
      .then(()=>{
        notifications.showSuccess('Estatus '+ item.name + ' Actualizado')
      })
      .catch((e)=>{
        notifications.showError('Error Actualizando Estatus '+e)
      })
    },
  },
  watch:{
    options:{
      handler:'fetch',
      deep:true
    }
  },
  async mounted(){
    this.fetch()
    
  }
}
</script>

<style>

</style>