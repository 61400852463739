var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',[_c('v-card-title',{staticClass:"titulo text-h4 mb-4"},[_c('v-icon',{staticClass:"mr-2 align-items-center"}),_vm._v(_vm._s(_vm.provider.id>-1?'Actualizar Proveedor':'Crear Proveedor')+" ")],1),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('ValidationProvider',{attrs:{"name":"RUT","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mb-2",attrs:{"dense":"","label":"RUT","prepend-icon":"fas fa-id-badge","hint":"Ingresar número con guión y dígito verificador","outlined":"","error-messages":errors,"loading":_vm.loading,"append-icon":_vm.rutValido?'fas fa-check':''},model:{value:(_vm.provider.rut),callback:function ($$v) {_vm.$set(_vm.provider, "rut", $$v)},expression:"provider.rut"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Nombre","prepend-icon":"mdi-file-document","outlined":"","error-messages":errors,"loading":_vm.loading},model:{value:(_vm.provider.name),callback:function ($$v) {_vm.$set(_vm.provider, "name", $$v)},expression:"provider.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Condición de Pago","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","label":"Condición de Pago (Días)","prepend-icon":"fas fa-calendar-alt","type":"number","min":0,"outlined":"","error-messages":errors},model:{value:(_vm.provider.payment),callback:function ($$v) {_vm.$set(_vm.provider, "payment", $$v)},expression:"provider.payment"}})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-btn',{attrs:{"type":"submit","color":"success","loading":_vm.loading}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("fas fa-sd-card")]),_vm._v("Guardar")],1)],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }